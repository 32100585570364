import React from 'react';
import { graphql } from 'gatsby';
import ListItem from '../images/boxes/listitem.svg';
import styles from './About-us.module.scss';
import aboutBoxes from '../images/boxes/about_boxer.svg';
import Img from 'gatsby-image';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';

export const query = graphql`
  query AboutPageQuery {
    LH2leak: file(relativePath: { eq: "om-oss/LH2leak.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Olav: file(relativePath: { eq: "om-oss/olav.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Eirik: file(relativePath: { eq: "om-oss/eirik.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Britt: file(relativePath: { eq: "om-oss/BESold.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const AboutPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <Layout>
        <SEO title="About us. We are a small company aiming to facilitate for a safe transition to a hydrogen economy." />
        <div
          className={`grid_text_image grid_vertical_padding sectionVeryLong pb-48 ${styles.container}`}
        >
          <div>
            <h1 className={styles.welcomeText}>Who we are</h1>
            <p className={styles.descriptionText}>
              <b>Never waste a good crisis</b> <br />
              In the wake of the global COVID-19 pandemic, which precipitated unprecedented declines 
              in oil prices and widespread economic uncertainty, Olav Roald Hansen took decisive action. 
              With 27 years of experience in research and development, as well as consulting in the 
              safety and risk sector, Olav opted to part ways with his employer and embark on an 
              entrepreneurial journey. He was joined by his son, Eirik Søreide Hansen, an aspiring 
              Chemical Process Engineer from the Norwegian University of Science and Technology (NTNU). 
              Eirik's academic focus on hydrogen production from ethanol was temporarily halted due to 
              the university's closure, providing an opportune moment to redirect his efforts toward this 
              new venture. In the fall of 2023, Britt Elin Salt joined the company, bringing with her 
              extensive experience from previous jobs within process, management and technical safety at 
              both onshore and offshore major accident industries, with a special focus on hydrogen and 
              ammonia since 2020.
            </p>
            <p className={styles.descriptionText}>
              <b>HYEX Safety - Hydrogen first</b> <br />
              As efforts intensify globally to meet climate goals and transition away from fossil fuels, 
              hydrogen and its carriers, such as ammonia, are increasingly seen as pivotal components in 
              decarbonising various sectors. However, the explosive nature of hydrogen and the toxicity 
              of ammonia necessitate a nuanced understanding of the associated risks. A single adverse 
              incident involving these energy carriers could potentially tarnish their reputation, 
              thereby stalling broader adoption.

              HYEX Safety concentrates primarily on conducting rigorous safety and risk assessments 
              related to hydrogen and its carriers. The firm also specialises in executing complex 
              Computational Fluid Dynamics (CFD) studies where high levels of precision are imperative. 
              These services encompass incident investigation, evaluative studies for problem 
              identification and design improvement, as well as planning and interpreting experimental 
              data.

            </p>
          </div>
          <div>
            <div className={styles.boxContainer}>
              <figure className={`headerImageHorizontal fullWidthMobile ${styles.box1}`}>
                <div>Quality</div>
                <span className={styles.subText}>
                  We know our craft, with decades of experience from the field. We familiarise
                  ourselves with your situation and targets, and we provide you with knowledge about
                  the safety of your projects.
                </span>
              </figure>
              <figure className={`headerImageHorizontal fullWidthMobile ${styles.box2}`}>
                <div>Creativity</div>
                <span className={styles.subText}>
                  We frequently discover additional problems while performing a risk analysis. In
                  these cases, we go wide and look for a cost-efficient solution to the new problems
                  as well.
                </span>
              </figure>
              <figure className={`headerImageHorizontal fullWidthMobile ${styles.box3}`}>
                <div>Reliability</div>
                <span className={styles.subText}>
                  You can trust us. We seek a good dialogue with you throughout the project,
                  evaluating solutions together. You know the situation best. We strive to be
                  flexible and adjust to your needs.
                </span>
              </figure>
            </div>
          </div>
        </div>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong mb-48">
          <div>
            <h1 className={styles.welcomeText}>Our tools</h1>
            <p className={styles.descriptionText}>
              <b>CFD -faster, better and beyond!</b> <br />
              Contrary to common misconceptions that deem Computational Fluid Dynamics as primarily 
              a research domain fraught with exorbitant costs and reliability concerns, HYEX Safety 
              has innovatively optimised this tool. Throughout his career, Olav has emphasised the 
              ability to swiftly prepare and execute simulations, generating precise and often 
              groundbreaking results, beyond the stated application/validity range of the software 
              (relevant for hydrogen is e.g. the modelling of DDT and detonation, tank burst and 
              LH2-dispersion).
            </p>
            <div className={styles.descriptionText}>
              Our golden CFD principles:
              <div className={styles.projectListContainer}>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Computational tasks 
                  should be defined within a matter of minutes or, at most, a few hours.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Simulations ought to 
                  be completed within a brief time frame, ideally a few hours or, at most, by the 
                  following day.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Utilised CFD tools 
                  must adhere to guidelines underpinned by empirical validation and avoid reliance on 
                  arbitrary parameters.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Persist in pushing 
                  the boundaries of what is considered achievable.
                </div>
              </div>
            </div>

            <p className={styles.descriptionText}>
              For workshops focusing on interactive design screening, HYEX Safety has developed a 
              suite of proprietary consequence screening models for hydrogen and ammonia projects. 
              These models provide instant predictions on variables such as outflow rate, dispersion 
              distances, gas accumulation, fire radiation, and blast outcomes. They serve as invaluable 
              tools during workshops and can form the foundation of comprehensive risk assessment 
              studies. When a detailed 3D layout is critical, more accurate CFD predictions are 
              performed using the FLACS software.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.LH2leak.childImageSharp.fluid} alt="FLACS simulation" />
            </figure>
            <div className={styles.figcaption}>
              <p>
                CFD-simulation of LH2-release experiment at the DNV Spadeadam test site. Photo: Teknisk Ukeblad.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.flexibleGrid}>
          <div className={styles.employeeContainer}>
            <figure className={`headerImageHorizontal fullWidthMobile ${styles.avatarImage}`}>
              <Img
                className={styles.image}
                fluid={data.Olav.childImageSharp.fluid}
                alt="Olav Roald Hansen"
              />
            </figure>
            <p className={styles.descriptionText}>
              <b>Olav Roald Hansen</b> <br />
              Founder
              <br />
              <a className={styles.contactInfo} href={`mailto:olav@hyexsafe.com`}>
                olav@hyexsafe.com
              </a>
              <br />
              <a className={styles.contactInfo} href={`tel:+4791171787`}>
                (+47) 91 17 17 87
              </a>
            </p>
          </div>
          <div className={styles.employeeContainer}>
            <figure className={`headerImageHorizontal fullWidthMobile ${styles.avatarImage}`}>
              <Img
                className={styles.image}
                fluid={data.Eirik.childImageSharp.fluid}
                alt="Eirik Søreide Hansen"
              />
            </figure>
            <p className={styles.descriptionText}>
              <b>Eirik Søreide Hansen</b> <br />
              Co-founder & Safety consultant
              <br />
              <a className={styles.contactInfo} href={`mailto:eirik@hyexsafe.com`}>
                eirik@hyexsafe.com
              </a>
              <br />
              <a className={styles.contactInfo} href={`tel:+4791183678`}>
                (+47) 91 18 36 78
              </a>
            </p>
          </div>
          <div className={styles.employeeContainer}>
            <figure className={`headerImageHorizontal fullWidthMobile ${styles.avatarImage}`}>
              <Img
                className={styles.image}
                fluid={data.Britt.childImageSharp.fluid}
                alt="Eirik Søreide Hansen"
              />
            </figure>
            <p className={styles.descriptionText}>
              <b>Britt Elin Salt</b> <br />
              Principal consultant
              <br />
              <a className={styles.contactInfo} href={`mailto:britt@hyexsafe.com`}>
                britt@hyexsafe.com
              </a>
              <br />
              <a className={styles.contactInfo} href={`tel:+4748118845`}>
                (+47) 48 11 88 45
              </a>
            </p>
          </div>
        </div>
        <div className={styles.boxesPattern}>
          <img src={aboutBoxes} alt="boxes" />
        </div>
      </Layout>
    </>
  );
};

export default AboutPage;
